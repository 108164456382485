import React from "react";
import { Context } from "./store";
import Plot from "react-plotly.js";
import {
  mean,
  sd,
  cpk,
  quantiles,
  shapiroWilk,
  roundDigits,
  roundSigFigs,
  tolerance_interval_factor,
  cpkl,
  cpku,
} from "./stats";

function DistributionAnalysis() {
  const ctx = React.useContext(Context);
  const state = ctx[0];

  const m = mean(state.data);
  const s = sd(state.data);

  const k = tolerance_interval_factor(
    parseFloat(state.conf_level),
    parseFloat(state.p),
    state.data.length,
    ["upper", "lower"].includes(state.tol_int_type)
  );

  let limitLines = [];
  let annotations = [];
  [state.lsl, state.usl].forEach((v) => {
    if (v) {
      limitLines = limitLines.concat([
        {
          type: "line",
          x0: v,
          x1: v,
          y0: 0,
          y1: 0.9,
          xref: "x",
          yref: "paper",
          line: { color: "#cc4125" },
        },
      ]);
      annotations = annotations.concat([
        {
          x: v,
          y: 0.95,
          xref: "x",
          yref: "paper",
          text: v,
          showarrow: false,
          font: { color: "#cc4125" },
        },
      ]);
    }
  });

  // Create tolerance interval lines
  if (k) {
    const ltl = m - k * s;
    const utl = m + k * s;
    limitLines = limitLines.concat([
      {
        type: "line",
        x0: ltl,
        x1: utl,
        y0: 0.5,
        y1: 0.5,
        xref: "x",
        yref: "paper",
        line: { color: "#777777" },
      },
    ]);

    if (["both", "upper"].includes(state.tol_int_type)) {
      limitLines = limitLines.concat([
        {
          type: "line",
          x0: ltl,
          x1: ltl,
          y0: 0.48,
          y1: 0.52,
          xref: "x",
          yref: "paper",
          line: { color: "#777777" },
        },
      ]);
      annotations = annotations.concat([
        {
          x: ltl,
          y: 0.55,
          xref: "x",
          yref: "paper",
          text: roundDigits(ltl, state.decimalPlaces + 1),
          showarrow: false,
          font: { color: "#333333" },
        },
      ]);
    }

    if (["both", "lower"].includes(state.tol_int_type)) {
      limitLines = limitLines.concat([
        {
          type: "line",
          x0: utl,
          x1: utl,
          y0: 0.48,
          y1: 0.52,
          xref: "x",
          yref: "paper",
          line: { color: "#777777" },
        },
      ]);
      annotations = annotations.concat([
        {
          x: utl,
          y: 0.55,
          xref: "x",
          yref: "paper",
          text: roundDigits(utl, state.decimalPlaces + 1),
          showarrow: false,
          font: { color: "#333333" },
        },
      ]);
    }
  }

  let [x, q] = quantiles(state.data);
  const shapiroWilkP = state.data.length > 3 ? shapiroWilk(state.data) : null;
  return (
    <div className="row mt-4">
      <div className="col-12">
        <div className="row">
          <div className="col-md-6">
            <h5 className="text-uppercase font-weight-bold">
              正态概率
            </h5>
            <Plot
              data={[
                {
                  type: "scatter",
                  mode: "markers",
                  x: q,
                  y: x,
                  marker: { color: "#afd3e7" },
                },
              ]}
              layout={{
                autosize: false,
                paper_bgcolor: "#f5f5f5",
                plot_bgcolor: "#f5f5f5",
                width: 540,

                margin: { l: 40, r: 20, b: 40, t: 20 },
                xaxis: { zeroline: false, title: "理论分位数" },
                yaxis: { zeroline: false },
              }}
              config={{ displaylogo: false }}
            />
            <table className="table table-sm text-mono small mt-4">
              <thead>
                <tr>
                  <th className="text-center">检验</th>
                  <th className="text-center">结果</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Shapiro-Wilk 检验</td>
                  <td>
                    {shapiroWilkP ? (
                      <span>
                        p = {roundSigFigs(shapiroWilkP, 2)} (
                        {shapiroWilkP < 0.05 ? "not " : null}normal)
                      </span>
                    ) : null}
                  </td>
                </tr>
              </tbody>
            </table>

            <h6 className="mt-5 text-uppercase font-weight-bold">了解</h6>
            <p>
              此面板旨在帮助确定输入数据是否来自于一个{" "}
              {/*<a href="https://en.wikipedia.org/wiki/Normal_distribution">*/}
                正态分布
              {/*</a>*/}
              {" "}
              的总体. 这些测试是假设检验，原假设是数据来自正态分布的样本。 如果  <code>p</code>值低于某个阈值 (通常为{" "}
              <code>p &lt; 0.05</code>),那么我们拒绝原假设，得出结论认为该数据不太可能来自正态分布的总体。
            </p>
          </div>
          <div className="col-md-6">
            <h5 className="text-uppercase font-weight-bold">
              能力研究
            </h5>
            <Plot
              data={[
                {
                  type: "histogram",
                  x: state.data,
                  marker: { color: "#afd3e7" },
                },
              ]}
              layout={{
                autosize: false,
                paper_bgcolor: "#f5f5f5",
                plot_bgcolor: "#f5f5f5",
                width: 540,
                margin: { l: 30, r: 20, b: 30, t: 20 },
                shapes: limitLines,
                annotations: annotations,
                xaxis: { rangemode: "nonnegative" },
                yaxis: { rangemode: "nonnegative" },
                datarevision: state.datarevision,
              }}
              config={{ displaylogo: false }}
            />
            <table className="table table-sm text-mono small mt-4">
              <thead>
                <tr>
                  <th className="text-center">属性</th>
                  <th className="text-center">值</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>样本数量</td>
                  <td>{state.data.length}</td>
                </tr>
                <tr>
                  <td style={{ width: "40%" }}>均值</td>
                  <td>
                    {state.data.length > 0
                      ? roundDigits(m || 0, state.decimalPlaces + 1)
                      : null}
                  </td>
                </tr>
                <tr>
                  <td>标准差</td>
                  <td>
                    {state.data.length > 0
                      ? roundDigits(s || 0, state.decimalPlaces + 1)
                      : null}
                  </td>
                </tr>
                <tr>
                  <td>Cpk</td>
                  <td>
                    {state.data.length > 0 && (state.lsl || state.usl) ? (
                      roundDigits(cpk(m, s, state.lsl, state.usl) || 0, 2)
                    ) : (
                      <i>n/a</i>
                    )}
                    {state.data.length > 0 && state.lsl && state.usl ? (
                      <span style={{ color: "#777777" }}>
                        {" (" +
                          roundDigits(cpkl(m, s, state.lsl), 2) +
                          " lower, " +
                          roundDigits(cpku(m, s, state.usl), 2) +
                          " upper)"}
                      </span>
                    ) : null}
                  </td>
                </tr>
                {k ? (
                  <tr>
                    <td>公差区间</td>
                    <td>
                      {["both", "upper"].includes(state.tol_int_type)
                        ? roundDigits(m - k * s, state.decimalPlaces + 1)
                        : "-Inf"}{" "}
                      to{" "}
                      {["both", "lower"].includes(state.tol_int_type)
                        ? roundDigits(m + k * s, state.decimalPlaces + 1)
                        : "Inf"}
                      <br />
                      {"k = " + roundDigits(k, 3)}
                      <br />
                      <span style={{ color: "#777777" }}>
                        {100 * state.conf_level +
                          "% C, " +
                          100 * state.p +
                          "% P, n = " +
                          state.data.length}
                      </span>
                    </td>
                  </tr>
                ) : null}
                <tr>
                  <td>排序数据</td>
                  <td>
                    {state.data
                      ? state.data
                          .sort((a, b) => a - b)
                          .map((v) => roundDigits(v, state.decimalPlaces))
                          .join(", ")
                      : null}
                  </td>
                </tr>
              </tbody>
            </table>
            <h6 className="mt-5 text-uppercase font-weight-bold">了解</h6>
            <p>
              在此面板中，我们查看数据的直方图，并叠加任何规格限制和公差区间。图形输出快速地直观显示了数据相对于规格限制的位置。
            </p>
            <p>
              过程能力指数 <i>Cpk</i> 是衡量数据与其指定限制接近程度的指标。Cpk 为 1 意味着数据的均值距离其最近的限制 3 个标准差。
            </p>
            <p>
              {" "}
              {/*<a href="https://www.itl.nist.gov/div898/handbook/prc/section2/prc263.htm">*/}
                公差区间
              {/*</a>{" "}*/}
              是一个范围，其中规定的比例 <code>P</code> 的总体位于该范围内，并具有给定的置信水平<code>C</code>.
              此公差区间与工程公差无关，我们在此称之为规格限制以避免混淆。公差区间的准确性基于总体正态分布的假设，因此该页面上的正态性检验是一个关键的起点。
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DistributionAnalysis;
