import React from "react";
import { Context } from "./store";
import {
    roundDigits,
    tolerance_interval_factor,
  } from "./stats";

const SampleSize = () => {
    const [state, dispatch] = React.useContext(Context);

    const handleFormChange = (actionType, value) => {
      dispatch({ type: actionType, payload: value });
    };
  
    const k = tolerance_interval_factor(
        parseFloat(state.conf_level),
        parseFloat(state.p),
        state.kn,
        ["upper", "lower"].includes(state.tol_int_type)
      );


  return (
    <div className="row mt-4">
      <div className="col-12">
        <h3 className="text-uppercase font-weight-bold">
            样本量确定
        </h3>

        <div className="row">
          <div className="col-md-6">
            <h5 className="text-uppercase font-weight-bold">
                属性（通过/不通过）数据
            </h5>
            <p>
                如果我们没有变量型数值数据，则必须使用基于累积分布的零失效属性方法。这有时被称为 <code>c=0</code> 表。请注意，由于我们不知道每个样本通过的余量，这种方法所需的样本量通常比变量数据要高。如果可以使用生成变量数据的不同测量方法，通常这是一个更好的选择。
            </p>
            <h6 className="text-uppercase font-weight-bold">计算</h6>
            <p>
                样本量可以通过以下公式计算：
            </p>
            <p className="text-center">
              <code>n = ln(1 - C) / ln(R)</code>
            </p>
            <p>
                将此值向上舍入到下一个整数。典型置信度和可靠性水平所需的样本量如下所示。
            </p>
            <table className="table table-sm small text-mono">
              <thead>
                <tr>
                  <th className="text-center">风险等级</th>
                  <th className="text-center">置信度</th>
                  <th className="text-center">可靠性</th>
                  <th className="text-center">所需样本量</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Low</td>
                  <td className="text-center">0.90</td>
                  <td className="text-center">0.80</td>
                  <td className="text-center">11</td>
                </tr>
                <tr>
                  <td>Medium</td>
                  <td className="text-center">0.95</td>
                  <td className="text-center">0.90</td>
                  <td className="text-center">29</td>
                </tr>
                <tr>
                  <td>High</td>
                  <td className="text-center">0.95</td>
                  <td className="text-center">0.95</td>
                  <td className="text-center">59</td>
                </tr>
                <tr>
                  <td>Critical</td>
                  <td className="text-center">0.95</td>
                  <td className="text-center">0.99</td>
                  <td className="text-center">299</td>
                </tr>
              </tbody>
            </table>
            <h6 className="text-uppercase font-weight-bold">解释</h6>
              <p> 如果所有 <code>n</code> 个样本都通过，我们可以以 <code>C%</code> 的置信度认为至少 <code>R%</code> 的总体将会通过。 </p>
              <h6 className="text-uppercase font-weight-bold"> 推荐接受标准 </h6>
              <p> 所有 <code>n</code> 个样本必须通过。 </p>
          </div>
            <div className="col-md-6">
                <h5 className="text-uppercase font-weight-bold">变量数据</h5>
                <p> 测试某一部件的总体是否符合某些指定限制的最佳统计方法是公差区间。公差区间是一个统计区间，其中指定比例 <code>P</code> 的样本总体落入该区间，且具有置信水平 <code>C</code>。这里的 <i>公差</i> 与尺寸公差无关。当观察一组数据时，其参数（均值、标准差）不一定与总体完全一致。因此，我们需要考虑抽样误差的不确定性，这将使我们的公差区间变得 <i>更宽</i>。
                </p>
                <p> 公差区间的准确性基于总体服从正态分布的假设，因此正态性检验是一个关键的起点。 </p>
                <h6 className="text-uppercase font-weight-bold">计算</h6> <p> 公差区间的上限和下限形式为： </p>
                <p className="text-center"><code>(m - k*s, m + k*s)</code></p>
                <p> 其中 <code>m</code> 是样本均值，<code>s</code> 是样本标准差。 </p>
                <p> 公差区间的 <code>k</code> 值计算涉及复杂的分布。对于正态分布数据的一侧公差区间，有一个使用非中心 t
                    分布的精确解法。对于双侧公差区间，有基于卡方分布的解法。实际上，通常使用计算器或查找表来找到给定 <code>C</code>、<code>P</code> 和 <code>n</code> 的 <code>k</code> 值。
                </p>
                <h6 className="text-uppercase font-weight-bold">计算器</h6>

                <form>
                    <div className="form-group">
                        <label>公差区间类型</label>
                        <select
                            className="form-control"
                            onChange={(e) => {
                                handleFormChange("UPDATE_TOL_INT_TYPE", e.target.value);
                            }}
                            value={state.tol_int_type}
                        >
                            <option value="both">双侧</option>
                            <option value="upper">单侧（上限）</option>
                            <option value="lower">单侧（下限）</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label>置信水平 (C)</label>
                        <input
                            className="form-control"
                            onChange={(e) => {
                                handleFormChange("UPDATE_C", e.target.value);
                            }}
                            value={state.conf_level || ""}
                        />
                        <span className="small font-italic text-danger">
                  {state.errors.conf_level}
                </span>
                    </div>

                    <div className="form-group">
                        <label>合格比例 (P)</label>
                        <input
                            className="form-control"
                            onChange={(e) => {
                                handleFormChange("UPDATE_P", e.target.value);
                            }}
                            value={state.p || ""}
                        />
                        <span className="small font-italic text-danger">
                  {state.errors.p}
                </span>
                    </div>

                    <div className="form-group">
                        <label>样本量 (n)</label>
                        <input
                            className="form-control"
                            onChange={(e) => {
                                handleFormChange("UPDATE_KN", e.target.value);
                            }}
                            value={state.kn || ""}
                        />
                        <span className="small font-italic text-danger">
                  {state.errors.kn}
                </span>
                    </div>

                </form>
                <table className="table table-sm text-mono small mt-4">
                    <thead>
                    <tr>
                        <th className="text-center">属性</th>
                        <th className="text-center">值</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>k</td>
                        <td>{k ? (roundDigits(k, 3)) : null}<br/>
                            <span style={{color: "#777777"}}>
                                            {k ? ("for " + 100 * state.conf_level +
                                                "% C, " +
                                                100 * state.p +
                                                "% P, n = " +
                                                state.kn) : null}
                                        </span></td>
                    </tr>
                    </tbody>
                </table>
                <h6 className="text-uppercase font-weight-bold">解释</h6>
                <p> 我们以 <code>C%</code> 的置信度认为至少 <code>P%</code> 的总体位于区间 <code>(m - k*s, m +
                    k*s)</code> 内。 </p>
                <h6 className="text-uppercase font-weight-bold"> 推荐接受标准 </h6>
                <p> 公差区间必须完全落在规格限制内，即满足以下两个条件： </p>
                <p className="text-center mb-0"><code>m +
                k*s &lt; 上规格限 (USL)</code></p>
                <p className="text-center"><code>下规格限 (LSL) &lt; m - k*s</code></p>
            </div>
        </div>
          <p></p>
      </div>
    </div>
  );
};

export default SampleSize;
